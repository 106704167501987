/* ICONS */
@import "~/material-design-icons/iconfont/material-icons.css";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css');
// @import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import "~bootstrap/scss/bootstrap";

/* FONTS */
@import 'fonts/_hk-grotesk.scss';
@import 'fonts/inter.scss';

/* ABSTRACTS */
@import 'abstracts/_variables.scss';
@import 'abstracts/_mixins.scss';


/* BASE */
@import 'base/_typography.scss';
@import 'base/_colors.scss';
@import 'base/_reset.scss';


/* LAYOUT */
@import 'layout/_header.scss';
@import 'layout/_menu-left.scss';


/* COMPONENTS */
@import 'components/_sticky-page-header.scss';
@import 'components/_card.scss';
@import 'components/_table.scss';
@import 'components/_label.scss';
@import 'components/_input.scss';
@import 'components/_button.scss';
@import 'components/_swich.scss';
@import 'components/_dialog.scss';
@import 'components/_alert.scss';
@import 'components/_group-button.scss';
@import 'components/_tooltip.scss';
@import 'components/_checkbox.scss';
@import 'components/_toast.scss';
@import 'components/_implementStyle.scss';
@import 'components/_circle-icon.scss';
@import 'components/_status-item.scss';
@import 'components/_status-list.scss';
@import 'components/_calendar.scss';
@import 'components/_guide-stepper.scss';
@import 'components/_stepper.scss';
@import 'components/_collapse.scss';
@import 'components/_autosuggest.scss';
@import 'components/_select.scss';
@import 'components/_date-range-picker.scss';
@import 'components/_tabs.scss';
@import 'components/_list.scss';
@import 'components/_avatar-badge.scss';
@import 'components/_cropper.scss';
@import 'components/_rich-text-editor.scss';


/* PAGES */
@import 'pages/_sign-in.scss';
@import 'pages/_sign-up.scss';
@import 'pages/_page-not-found.scss';
@import 'pages/_terms-conditions.scss';

@import 'pages/dashboard/_dashboard.scss';
@import 'pages/live-tracking/_live-tracking.scss';

@import 'pages/settings/_onboarding.scss';

/* AdminLTE Styles*/
// @import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback');
// @import '../plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css';
// @import '../plugins/icheck-bootstrap/icheck-bootstrap.min.css';
// @import '../plugins/jqvmap/jqvmap.min.css';
// @import '../dist/css/adminlte.min.css';
// @import '../plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
// @import '../plugins/daterangepicker/daterangepicker.css';
// @import '../plugins/summernote/summernote-bs4.min.css';

.report-header {
    font-weight: 700;
}

.item-table-header {
    thead {
      background-color: #fff !important;
      tr {
        th {
        padding: 10px 5px;
        background-color: #fff;
        color: #000;
        border: 1px solid rgb(207, 207, 207);
        }
      }
    }
    tbody {
      background-color: #fff !important;
      tr {
        td {
        padding: 10px 5px;
        background-color: #fff;
        color: #000;
        border: 1px solid rgb(207, 207, 207) ;
        }
      }
    }
  }
  .remove-icon {
    background-color: rgba(241, 63, 122, 0.89);
    border-radius: 50%;
    width:20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    :hover {
    background-color: rgba(214, 13, 80, 0.89);
    border-radius: 50%;
    width:20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    }
    span {
      font-size: 12px;
      padding: auto;
      color: #fff;
    }
  }
  
  .normal-pie-chart {
    .recharts-default-legend{
      width: 140px !important;
      .recharts-legend-item {
        margin-right: 0px !important;
      }
    }
  }
  
.header-warpper{
  .notification {
      position: "relative";
      width: fit-content;
      display: inline-flex;
      margin-left: 50%;
      border-radius: 7px;
      margin-right: -3px;
  }
  .iconSection {
      position: relative;
      width: fit-content;
      display: inline-flex;
  }
  .notificationBar {
      display: block;
      box-shadow: 2px 3px 17px -3px #8888888c;
      padding: 15px;
      right: 0;
      z-index: 1;
      background: #f4f4f4;
      color: initial;
  }
  .notification:hover .notificationBar {
      display: block;
  }
  .lineItmes {
    background: white !important;
    padding: 8px !important;
    margin: 10px;
    margin-left: 0px !important;
    border-radius: 4px;
  }

  #notification-layout-icon {
    width: 50px;
    height: 40px;
    background-color: #d7d7d7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iconBadge {
      background: red;
      width: 15px;
      display: block;
      text-align: center;
      height: 15px;
      border-radius: 50%;
      color: #fff;
      font-size: 9px;
      line-height: 15px;
      position: absolute;
      right: 0;
      bottom: 13px;
      left: 12px;
  }
  .innerNotiContainer {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 5px;
      scrollbar-color: #0d6efd;
    }
    &::-webkit-scrollbar-track {
        background-color: #eceef2;
        border-radius: 180px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #0d6efd;
        border-radius: 180px;
    }
  }
  @media screen and (max-width: 480px) {
    .notificationBar {
      left: -115px !important;
    }
  }
}
.customer-header-profile {
   border-left: 1px solid #747171;
   padding-left: 15px;
  .MuiButton-text {
    padding: 0px;
  }
  .MuiButton-root {
    min-width: 40px !important;
  }
}

.customer-header-profile-2 {
  padding-left: 15px;
 .MuiButton-text {
   padding: 0px;
 }
 .MuiButton-root {
   min-width: 40px !important;
 }
}

header.makeStyles-appBarOpen-5 {
  padding-right: 0px !important;
}

.optimise-btn {
  max-width: 200px;
}

.Unselected-Jobs,.Unassigned-Jobs {
    box-shadow: none;
    border: none;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    .company-name {
      display: none;
    }
    .custom-container {
      margin-bottom: 0px !important;
    }
    thead {
      display: none;
    }
}
.Unselected-Jobs.first,.Unassigned-Jobs.first {
    margin-top: 1.5rem !important;
    padding-top: 20px !important;
    .company-name {
      display: block;
    }
    thead {
      display: table-header-group;
    }
}
.multi-email-container {
  padding: 5px;
  margin-left: 10px;
  border-radius: 5px;
  .email-item {
    display: flex;
    align-items: center; 
    border-radius: 5px;
    background: #eaedf2;
    margin-bottom: 5px;
    padding: 3px;
  }
  button {
    margin-left: 5px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
  span {
    margin-left: 5px;
    flex: 1;
    width: 80%;
    word-break: break-word;
  }
}
.increase-icon {
  position: absolute;
  right: -85px;
  z-index: 99;
}
.decrease-icon {
  position: absolute;
  right: -18px;
}