.custom-status-list-component {
    position: relative;
    width: 100%;
    ul {
        margin: 0px;
        padding: 0px;
        text-align: center;
        list-style-type: none;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0px;
        justify-self: space-around;
        li {
            position: relative;
            display: inline-block;
            margin: 0 10px;
            border-bottom: 2px solid transparent;
            padding-bottom: 13px;
            cursor: pointer;
            // width: 110px!important;
            &:hover, &.active {
                border-bottom: 2px solid $color-second;

                &:after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 8px solid $color-second;
                    left: 45%;
                    bottom: 0px;
                }
            }
        }
    }
}

.custom-status-list-wrapper {
    position: relative;
}