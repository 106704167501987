.custom-status-item-component {
    .custom-text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        font-family: $font-family-semibold;
        color: $color-secondary;
        span {
            text-transform: uppercase;
            font-size: 12px;
            font-family: $font-family-semibold;
            color: $color-secondary;
        }

        .custom-dot {
            position: relative;
            top: 1px;
            font-size: 10px;
            color: $color-secondary;
            margin-left: 5px;
            margin-right: 5px;
        }
        .custom-dot-cus {
            position: relative;
            top: 1px;
            font-size: 16px;
            color: $color-secondary;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .custom-count {
        text-align: center;
        font-size: 12px;
        font-family: $font-family-medium;
        color: $color-secondary;

        span {
            font-size: 12px;
            font-family: $font-family-medium;
            color: $color-secondary;
        }
    }

    .custom-count-pl {
        padding-left: 10px;
    }
    
    .MuiBadge-badge {
        top: 9px;
        left: -10px;
    }
}


$color-total-orders: #007CDE;
$color-info-received: #0094ff;
$color-on-hold: #BB7BFD;
$color-pending: #FF2386;
$color-not-assigned: #75b8c8;
$color-assign-pending: #777777;
$color-assigned-to-vendor: #F9CD4D;
$color-assigned: #F9CD4D;
$color-acknowledged: #a9629e;
$color-in-progress: #FF8906;
$color-completed: #1ACE85;
$color-text-completed: #1ACE85;
$color-self-collect: #000000;
$color-cancelled: #696A79;
$color-failed: #F14254;
$white: #FFFFFF;

.status {
    &.not-assigned {
        color: $color-not-assigned !important;
    }
    &.total-orders {
        color: $color-total-orders !important;
    }
    &.info-received {
        color: $color-info-received !important;
    }
    &.on-hold {
        color: $color-on-hold !important;
    }
    &.pending {
        color: $color-pending !important;
    }
    &.assign-pending {
        color: $color-assign-pending !important;
    }
    &.assigned-to-vendor {
        color: $color-assigned-to-vendor !important;
    }&.assigned {
        color: $color-assigned !important;
    }
    &.acknowledged {
        color: $color-acknowledged !important;
    }
    &.in-progress {
        color: $color-in-progress !important;
    }
    &.completed {
        color: $color-text-completed !important;
    }
    &.self-collect {
        color: $color-self-collect !important;
    }
    &.cancelled {
        color: $color-cancelled !important;
    }
    &.failed {
        color: $color-failed !important;
    }
}

.circle-progress-label {
    border-radius: 5px;
    color: white !important;
    background-color: #000000;
    width: 90% !important;
    min-width: 90% !important;
    padding: 5px 15px;
    &.not-assigned {
        background-color: $color-not-assigned !important;
        color: white !important;
    }
    &.total-orders {
        background-color: $color-total-orders !important;
        color: white !important;
    }
    &.info-received {
        background-color: $color-info-received !important;
        color: white !important;
    }

    &.on-hold {
        background-color: $color-on-hold !important;
        color: white !important;
    }
    &.pending {
        background-color: $color-pending !important;
        color: white !important;
    }
    &.assign-pending {
        background-color: $color-assign-pending !important;
        color: white !important;
    }
    &.assigned-to-vendor {
        background-color: $color-assigned-to-vendor !important;
        color: white !important;
    }
    &.assigned {
        background-color: $color-assigned !important;
        color: white !important;
    }
    &.acknowledged {
        background-color: $color-acknowledged !important;
        color: white !important;
    }
    &.in-progress {
        background-color: $color-in-progress !important;
        color: white !important;
    }
    &.completed {
        background-color: $color-text-completed !important;
        color: white !important;
    }
    &.self-collect {
        background-color: $color-self-collect !important;
        color: white !important;
    }
    &.cancelled {
        background-color: $color-cancelled !important;
        color: white !important;
    }
    &.failed {
        background-color: $color-failed !important;
        color: white !important;
    }
}

.customer-tag {
    border-radius: 50px;
    padding: 3px 10px;
    min-width: 30px !important;
    width: 40px auto !important;
    max-width: 60px !important;
    text-align:center;
    color: #ffffff;
    margin:4px;
    &.not-assigned {
        background-color: $color-not-assigned !important;
        color: white !important;
    }
    &.total-orders {
        background-color: $color-total-orders !important;
        color: white !important;
    }
    &.on-hold {
        background-color: $color-on-hold !important;
        color: white !important;
    }
    &.pending {
        background-color: $color-pending !important;
        color: white !important;
    }
    &.assign-pending {
        background-color: $color-assign-pending !important;
        color: white !important;
    }
    &.assigned-to-vendor {
        background-color: $color-assigned-to-vendor !important;
        color: white !important;
    }
    &.assigned {
        background-color: $color-assigned !important;
        color: white !important;
    }
    &.acknowledged {
        background-color: $color-acknowledged !important;
        color: white !important;
    }
    &.in-progress {
        background-color: $color-in-progress !important;
        color: white !important;
    }
    &.completed {
        background-color: $color-text-completed !important;
        color: white !important;
    }
    &.self-collect {
        background-color: $color-self-collect !important;
        color: white !important;
    }
    &.cancelled {
        background-color: $color-cancelled !important;
        color: white !important;
    }
    &.failed {
        background-color: $color-failed !important;
        color: white !important;
    }
}

.status-label {
    border-radius: 6px !important;
    height: 26px !important;
    border: 0px !important;

    $opacity: 0.1;

    &.w-100 {
        width: 100%;
    }
    
    &.active {
        background-color: rgba($color-primary, $opacity) !important;

        span {
            color: $color-primary;
        }
    }
    &.inactive {
        background-color: rgba($color-second, $opacity) !important;

        span {
            color: $color-second;
        }
    }

    &.not-assigned {
        background-color: rgba($color-not-assigned, $opacity) !important;

        span {
            color: $color-not-assigned;
        }
    }
    &.on-hold {
        background-color: rgba($color-on-hold , $opacity) !important;

        span {
            color: $color-on-hold ;
        }
    }
    &.pending {
        background-color: rgba($color-pending, $opacity) !important;

        span {
            color: $color-pending;
        }
    }
    &.assign-pending {
        background-color: rgba($color-assign-pending, $opacity) !important;

        span {
            color: $color-assign-pending;
        }
    }
    &.assigned-to-vendor {
        background-color: rgba($color-assigned-to-vendor, $opacity) !important;

        span {
            color: $color-assigned-to-vendor;
        }
    }
    &.assigned {
        background-color: rgba($color-assigned, $opacity) !important;

        span {
            color: $color-assigned;
        }
    }
    &.acknowledged {
        background-color: rgba($color-acknowledged, $opacity) !important;

        span {
            color: $color-acknowledged;
        }
    }
    &.in-progress {
        background-color: rgba($color-in-progress, $opacity) !important;

        span {
            color: $color-in-progress;
        }
    }
    &.completed {
        background-color: rgba($color-text-completed, $opacity) !important;

        span {
            color: $color-text-completed;
        }
    }
    &.self-collect {
        background-color: rgba($color-self-collect, $opacity) !important;

        span {
            color: $color-self-collect;
        }
    }
    &.cancelled {
        background-color: rgba($color-cancelled, $opacity) !important;

        span {
            color: $color-cancelled;
        }
    }
    &.failed {
        background-color: rgba($color-failed, $opacity) !important;

        span {
            color: $color-failed;
        }
    }
}
.status-label-history {
    &.not-assigned {
        background-color: unset !important;

        span {
            color: $color-not-assigned;
        }
    }
    &.on-hold {
        background-color: unset !important;

        span {
            color: $color-on-hold ;
        }
    }
    &.pending {
        background-color: unset !important;

        span {
            color: $color-pending;
        }
    }
    &.assign-pending {
        background-color: unset !important;

        span {
            color: $color-assign-pending;
        }
    }
    &.assigned {
        background-color: unset !important;

        span {
            color: $color-assigned;
        }
    }
    &.assigned-to-vendor {
        background-color: unset !important;

        span {
            color: $color-assigned-to-vendor;
        }
    }
    &.acknowledged {
        background-color: unset !important;

        span {
            color: $color-acknowledged;
        }
    }
    &.in-progress {
        background-color: unset!important;

        span {
            color: $color-in-progress;
        }
    }
    &.completed {
        background-color: unset !important;

        span {
            color: $color-text-completed;
        }
    }
    &.self-collect {
        background-color: unset !important;

        span {
            color: $color-self-collect;
        }
    }
    &.cancelled {
        background-color: unset !important;

        span {
            color: $color-cancelled;
        }
    }
    &.failed {
        background-color: unset !important;

        span {
            color: $color-failed;
        }
    }
}

.status-btn {
    &.not-assigned {
        button {
            background-color: $color-not-assigned !important;
        }
    }
    &.assign-pending {
        button {
            background-color: $color-assign-pending !important;
        }
    }
    &.info-received {
        button {
            background-color: $white !important;
            border: 1px solid $color-info-received !important;
            span{
            color: $color-info-received !important;
            svg {
                path {
                    fill: $color-info-received !important;
                }
            }
            }
        }
    }
    &.on-hold {
        button {
            background-color: $color-on-hold !important;
            }
    }
    &.pending {
        button {
            background-color: $color-pending !important;
            }
    }
    &.assigned {
        button {
            background-color: $color-assigned !important;
        }
    }
    &.assigned-to-vendor {
        button {
            background-color: $color-assigned-to-vendor !important;
        }
    }

    &.acknowledged {
        button {
            background-color: $color-acknowledged !important;
        }
    }
    &.in-progress {
        button {
            background-color: $color-in-progress !important;
        }
    }
    &.completed {
        button {
            background-color: $color-completed !important;
        }
    }
    &.self-collect {
        button {
            background-color: $color-self-collect !important;
        }
    }
    &.cancelled {
        button {
            background-color: $color-cancelled !important;
        }
    }
    &.failed {
        button {
            background-color: $color-failed !important;
        }
    }
}

.custom-status{
    &.status-btn {
        &.not-assigned {
            button {
                background-color: $color-not-assigned !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.assign-pending {
            button {
                background-color: $color-assign-pending !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.assigned {
            button {
                background-color: $color-assigned !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.assigned-to-vendor {
            button {
                background-color: $color-assigned-to-vendor !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.acknowledged {
            button {
                background-color: $color-acknowledged !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.in-progress {
            button {
                background-color: $color-in-progress !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.completed {
            button {
                background-color: $color-completed !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.self-collect {
            button {
                background-color: $color-self-collect !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.cancelled {
            button {
                background-color: $color-cancelled !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.failed {
            button {
                background-color: $color-failed !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
    }
}