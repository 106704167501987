.header-profile {
    button {
        img {}
        span {
            text-transform: capitalize;
            color: white;
            padding-left: 15px;
            padding-right: 15px;
        }
        svg {
            fill: white;

            rect, g, path {
                fill: white;
            }
        }
    }
}

.customer-header-profile {
    button {
        img {}
        span {
            text-transform: capitalize;
            color: rgb(10, 10, 10);
            padding-left: 0px;
            padding-right: 0px;
            @include respond-below('xs') {
                padding-right: 0px;
            }
        }
        svg {
            fill: rgb(10, 10, 10);

            rect, g, path {
                fill: rgb(10, 10, 10);
            }
        }
    }
}
.user-info-name {
    @include respond-below('md') {
        display: none !important; 
    }
}

#dropdown-basic {
    
}

.user-info-icon {
    @include respond-below('md') {
        display: none !important; 
    }
}


.noti-overlay {
    opacity: .5 !important;
    background-color: #000;
}
#customized-menu {
    .MuiPopover-paper {
        left: 718px;
    }
    .header {
        background: #424242;
        padding: 12px;
        color: #fff;
    }
    ul {
        padding: 0px;
        max-height: 300px;
        overflow-y: auto;
        li {
            white-space: unset;
        }
    }
    .noti-footer {
        text-align: center;
        font-weight: 700;
        border-top: 1px solid #bbb;
        padding: 7px 0;
        font-size: 11px;
        cursor: pointer;
        color: #4b7daa;
    }
}
.customized-menu-item {
    min-width: 350px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-block;
    }
    .details {
        margin-left: 10px;
        display: inline-block;
        .description {
            display: block;
            max-width: 248px;
            font-size: 12px;
            color: #585858;
        }
        .time {
            display: block;
            font-size: 11px;
            font-weight: 200;
            color: #aaa;
            text-align: left;
            .icon {
                vertical-align: top;
            }
            .time-text {
                font-size: 11px;
                font-weight: 200;
                color: #aaa;
            }
        }
    }
}
.notification-list {
    .time{
        text-align: left;
    }
}
#profile-menu {
    position: fixed;
    left:0;
    .MuiPopover-paper {
        padding: 0px 0px 0px 0px;
        overflow: hidden;
        max-height: 100%;

        ul {
            padding: 0px 0px 0px 0px;
        }
    }

    .user-info-right {
        padding-left: 0px;
    }
}

#profile-menu-customer {
        position: fixed;
        left:0;
    .MuiPopover-paper {
        padding: 0px 0px 0px 0px;
        overflow: hidden;
        max-height: 100%;
        top: 70px !important;
        ul {
            padding: 0px 0px 0px 0px;
        }
    }

    .user-info-right {
        padding-left: 0px;
    }
}

#download-menu-job {
    position: fixed;
    left:0;
    .MuiPopover-paper {
        padding: 0px 0px 0px 0px;
        overflow: hidden;
        max-height: 100%;
        top: 140px !important;
        right: 65px!important;
        left: unset!important;
        ul {
            padding: 0px 0px 0px 0px;
        }
    }

    .user-info-right {
        padding-left: 0px;
    }
}

.allocated-menu-job {
    &.custom-button-component button {
    padding: 9px 50px;
    width: 180px;
    border-radius: 50px;
    margin-left: 1em;
    font-size: 1em;
    background-color: #007CDE !important;
    border: 1px solid #007CDE;
    @media (max-width: 260px){
        padding: 6px 25px;
        width: 80% !important;
    }
    }
    
}
.user-info-2 {
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
    &-name {
        font-weight: bold;
    }
    &-email {
        color: #ADAFB3;
    }

}

.user-info {
padding: 6px 16px;
display: block;
align-items: center;
justify-content: center;
text-align: center;
min-width: 200px;
&-name {
    font-weight: bold;
}
&-email {
    color: #ADAFB3;
}

}



.profile-pic-logo {
    border-radius: 50%;
    object-fit: cover;
}

.MuiToolbar-root {
    .MuiBadge-root {
        display: none !important;
        padding: 15px 0;

        &:hover {
            cursor: pointer;
        }
    }
}

.active-header-style {
    border-bottom: 4px solid #007CDE;
    text-decoration: none !important;
}

.not-active-header-style {
    margin-bottom: 4px;
    text-decoration: none !important;
}

.header-toggle {
    min-height: 60px;
    // @include respond-below('xs') {
    //     min-width: 60px !important;
    //     width:60px !important;
    // }
}

.header-warpper {
    padding: 0px !important;
}

.upload-style {
    padding: 9px 25px;
    border-radius: 50px;
    margin-left: 1em;
    font-size: 1em;
    background-color: #007CDE !important;
    border: 1px solid #007CDE;
    &:hover {
    background-color: #007edec0 !important;
    border: 1px solid #007edec0;
    }
    &:disabled,
    &[disabled]{
        border: 1px solid #007edec0;
        background-color: #007edec0 !important;
    }
    svg {
        path {
        fill: #ffffff !important;
        }
    }
    @include respond-below('xs') {
       width: 80% !important;
       margin: 0em !important;
       margin-top: 4px !important;
    }
}

.two-btn-pos {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 5px;
}

.border-delete-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 16px;
    padding: 8px;
    display:flex;
    align-items: center;
    justify-content: center;
    color: #ff0000;
    border: 1px solid #FF0000;
    background-color: transparent !important;
    &:hover {
        background-color: #FF0000 !important;
        border: 1px solid #FF0000;
        color: #ffffff;
    }
}

.border-restore-btn {
    margin-left: 8px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 16px;
    padding: 8px;
    display:flex;
    align-items: center;
    justify-content: center;
    color: #009C6E;
    border: 1px solid #009C6E;
    background-color: transparent !important;
    &:hover {
        background-color: #009C6E !important;
        border: 1px solid #009C6E;
        color: #ffffff;
    }
}

.delete-style {
    padding: 9px 25px;
    border-radius: 50px;
    margin-left: 1em;
    font-size: 1em;
    background-color: #FF0000 !important;
    border: 1px solid #FF0000;
    &:hover {
    background-color: #FF0000 !important;
    border: 1px solid #FF0000;
    }
    &:disabled,
    &[disabled]{
        border: 1px solid #FF0000c0;
        background-color: #FF0000c0 !important;
    }
    svg {
        path {
        fill: #ffffff !important;
        }
    }
    @include respond-below('xs') {
       width: 80% !important;
       margin: 0em !important;
       margin-top: 4px !important;
    }
}


.driver-tag-style {
    div {
        button {
            &.selected {
                span {
                    color : #007CDE !important;
                }
            }
        }
    }
}

.force-width {
    width: 220px !important;
    padding: 9px 25px;
    border-radius: 50px;
    margin-left: 1em;
    font-size: 1em;
    background-color: #007CDE !important;
    border: 1px solid #007CDE;
    &:hover {
    background-color: #007edec0 !important;
    border: 1px solid #007edec0;
    }
    &:disabled,
    &[disabled]{
        border: 1px solid #007edec0;
        background-color: #007edec0 !important;
    }
    svg {
        path {
        fill: #ffffff !important;
        }
    }
    @include respond-below('xs') {
        display: block !important;
        width: 100% !important;
    }
}

.left-margin {
    margin-left: 1em !important;
}

.upload-style-search {
    padding: 9px 25px;
    border-radius: 50px;
    margin-left: 1em;
    font-size: 1em;
    min-width: 160px;
    background-color: #007CDE !important;
    border: 1px solid #007CDE;
    &:hover {
    background-color: #007edec0 !important;
    border: 1px solid #007edec0;
    }
    svg {
        path {
        fill: #ffffff !important;
        }
    }
    @include respond-below('xs') {
        min-width:80% !important;
        margin-top: 4px !important;
    }
}

.style-zone {
    padding: 0px 25px;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-left: 1em;
    border: 0px solid white;
    font-size: 1em;
    color: #ffffff !important;
    background-color: #007CDE !important;
    &:hover {
    background-color: #007edec0 !important;
    border: 0px solid white;
    }
}

.upload-style-cancel {
    padding: 9px 25px;
    border-radius: 50px;
    margin-left: 1em;
    font-size: 1em;
    border: 1px solid #aaaaaad1 !important;
    background-color: #aaaaaad1 !important;
    &:hover {
    background-color: #d1d1d1 !important;
    border: 1px solid #d1d1d1 !important;
    }
    svg {
        path {
        fill: #ffffff !important;
        }
    }
    @include respond-below('xs') {
        width: 80% !important;
        margin: 0em !important;
        margin-top: 4px !important;
     }
}

.upload-style-border {
    padding: 9px 25px;
    border-radius: 50px;
    margin-left: 1em;
    color: #007CDE;
    font-size: 1em;
    background-color: transparent !important;
    border: 1px solid #007CDE;
    &:hover {
        color: #007CDE;
    }
    svg {
        path {
        fill: #ffffff !important;
        }
    }
    @include respond-below('xs') {
        width: 80% !important;
        margin: 0em !important;
        margin-top: 4px !important;
     }
}

.header-tabs-cus {
    height: 60px !important;
    div {
        a {
            div {
                text-align: center;
            }
        }
    }
}

@media (max-width: 768px) {
    .header-tabs-cus {
        height: auto !important;
        padding-top:5px;
        font-size: 1em;
    }
    .img-hidden {
        display: none;
    }
}

.report-header {
    font-weight: 800;
}

.report-title {
    font-weight: normal;
    font-size: 16px;
}

.border-delete-b {
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid #000000;
    color:#000000;
}

.border-delete {
    border-radius: 50px;
    background-color: #FF0000;
    color: #ffffff;
    border:0px solid transparent;
}

.buttom-primary-dailog {
    border-radius: 50px;
    background-color: #007CDE;
    color: #ffffff;
    border:0px solid transparent;
}

.buttom-primary-dailog-cancel {
    border-radius: 50px;
    background-color: transparent;
    color: #007CDE;
    border:1px solid #007CDE;
}

.border-delete:hover {
    background-color: #cc0000;
}

.input-custom-style {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    div{
        border: 0px solid #c3c3c3 !important;
        input {
            border-bottom: 1px solid #c3c3c3;
        }
        button {
            border-radius: 50px;
            span {
            color:#ffffff;
            }
        }
    }
}