.terms-condition {
    // display: flex;
    
    .top-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respond-below('sm') {
            flex-direction: column;
        }
        .logo {
            margin: 32px 56px;
            img {
                width: auto;
            }
        }
        .signup-acc {
            display: flex;
            align-items: inherit;
            margin: 0 32px;
            div {
                margin-right: 16px;
                font-size: 16px;
            };
        }
    }
    .MuiTypography-gutterBottom {
        margin-bottom: 1em;
    }
}

.term-paragraph-wrap {
    margin: 20px;
    border: 2px !important;
}