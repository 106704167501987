.custom-table-component {
  span {
    svg.MuiTableSortLabel-icon {
        opacity: unset;
      }
    }
  table {
    thead {
      tr {
        th {
          background-color: #eaedf2;
          color: $color-secondary;
          font-family: $font-family-medium;
          font-size: 14px;
          // border: 0px;
          border: 1px solid #ababada3 !important;
          text-align: center;

          * {
            font-family: $font-family-medium;
            font-size: 14px;
          }

          .material-icons {
            font-family: 'Material Icons' !important;
          }

          &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #eaedf2;

        td {
          color: $color-secondary;
          font-family: $font-family-regular;
          font-size: 14px;
          // border: 0px;
          text-align: center;

          * {
            font-family: $font-family-regular;
          }
        }
      }
      /* for transport operator input*/
      #outlined-number {
        min-width: 100px;
        max-height: 15px !important;
        height: 15px !important;
        padding: 5px;
      }
    }
  }

  .custom-groupbar {
    margin-bottom: 10px;
    > div {
      > div {
        background-color: transparent !important;
        border-radius: 4px !important;
      }
    }
  }

  .MuiTableCell-root {
    padding: 5px;
  }
  .custom-button-component {
    &.status-btn{
      margin: auto;
    }
  }
  &.job-table {
    table {
      tbody {
        tr {
          border: 0px !important;
        }
      }
    }
    
  }

  .custom-button-component {
    &.status-btn{
      margin: auto;
    }
  }
  &.job-table-reason {
    table {
      tbody {
        tr {
          border: 0px !important;
        }
        &th {
          border: 0px !important;
        }
      }
    }
    
  }

  .MuiSvgIcon-root{
      font-size: 1em ;
  }
  &.job-table-list {
    table {
      tbody {
        tr {
          &.MuiTableRow-root {
            border-bottom: 1px solid #ababad63 !important;
            // border: 0px !important;
            height: auto !important;

            td {
              // border: 0px !important;
              border: 1px solid #ababad63 !important;
            }
          }
        }
      }
    }

    &.v-top {
      tr {
        vertical-align: top !important;
      }
    }
  }

  &.import-table {
    &.has-overflow {
      height: 500px;
      overflow: auto;
    }

    table {
      tbody {
        tr {
          td {
            padding-left: 5px !important;
            padding-right: 5px !important;
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}

.custom-table-component-2 {
      span {
        svg.MuiTableSortLabel-icon {
            opacity: unset;
          }
        }
      table {
        thead {
          tr {
            th {
              background-color: #eaedf2;
              color: $color-secondary;
              font-family: $font-family-medium;
              font-size: 14px;
              // border: 0px;
              border: 1px solid #ababada3 !important;
              text-align: center;

              * {
                font-family: $font-family-medium;
                font-size: 14px !important;
              }

              .material-icons {
                font-family: 'Material Icons' !important;
              }

              &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
              }
              &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid #eaedf2;

            td {
              color: $color-secondary;
              font-family: $font-family-regular;
              font-size: 14px !important;
              // border: 0px;
              text-align: center;

              * {
                font-family: $font-family-regular;
              }
            }
          }
          /* for transport operator input*/
          #outlined-number {
            min-width: 100px;
            max-height: 15px !important;
            height: 15px !important;
            padding: 5px;
          }
        }
      }

      .custom-groupbar {
        margin-bottom: 10px;
        > div {
          > div {
            background-color: transparent !important;
            border-radius: 4px !important;
          }
        }
      }

      .MuiTableCell-root {
        padding: 5px;
        width: unset !important;
      }
      .custom-button-component {
        &.status-btn{
          margin: auto;
        }
      }
      &.job-table {
        table {
          tbody {
            tr {
              border: 0px !important;
            }
          }
        }
        
      }

      .custom-button-component {
        &.status-btn{
          margin: auto;
        }
      }
      &.job-table-reason {
        table {
          tbody {
            tr {
              border: 0px !important;
            }
            &th {
              border: 0px !important;
            }
          }
        }
        
      }

      .MuiSvgIcon-root{
          font-size: 1em ;
      }
      &.job-table-list {
        table {
          tbody {
            tr {
              &.MuiTableRow-root {
                border-bottom: 1px solid #ababad63 !important;
                // border: 0px !important;
                height: auto !important;

                td {
                  // border: 0px !important;
                  border: 1px solid #ababad63 !important;
                }
              }
            }
          }
        }

        &.v-top {
          tr {
            vertical-align: top !important;
          }
        }
      }

      &.import-table {
        &.has-overflow {
          height: 500px;
          overflow: auto;
        }

        table {
          tbody {
            tr {
              td {
                padding-left: 5px !important;
                padding-right: 5px !important;
                padding-top: 5px;
                padding-bottom: 5px;
              }
            }
          }
        }
      }
}

.custom-grid-header {
  .MuiGrid-container {
    background-color: #eaedf2;
    min-height: 50px;
    border-radius: 4px;
    margin-right: 0px;
    .MuiGrid-item {
      color: $color-secondary;
      font-family: $font-family-medium;
      font-size: 14px;
      border: 0px;

      * {
        font-family: $font-family-medium;
      }

      .material-icons {
        font-family: 'Material Icons' !important;
      }
    }
  }
}

.custom-head-thead {
  .custom-head-tr {
    .custom-head-th {
      background-color: #eaedf2;
      color: $color-secondary;
      font-family: $font-family-medium;
      font-size: 14px;
      border: 0px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
      text-align: left !important;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding-left: 5px;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-right: 5px;
      }
    }
  }
}
.route-optimize {
  .MuiTableRow-head {
    display: none;
  }
}

.report-table {
  thead {
    tr {
      th {
        font-weight: 500;
        line-height: 1.5rem;
        padding: 16px;
      }
    }
  }

  tbody {
    tr {
      td {
        font-weight: 500;
        line-height: 1.5rem;
        padding: 16px;
        border-bottom: 1px solid rgb(234, 237, 242);

        a,
        span {
          font-size: 14px;
        }
      }
    }
  }
}

.sequence-circle {
  padding: 5px 11px;
  background: $color-active;
  color: #fff;
  border-radius: 50%;
  float: left;
  font-size: 12px;
  font-weight: bold;
}

.custom-invoice-table {
  width: 100%;
  text-align: center;
  .botom-cell-style {
    border: none;
    text-align: right;
    padding-right: 10px;
  }
  td {
    border: 1px solid;
    height: 35px;
  }
}
.float-right {
  float: right;
  width: 75%;
}

.bg-complete {
  background-color: #dff0d8;
}

.bg-failed {
  background-color: #ccc
}

.bg-allocation-collection {
  background-color: #AEDBFF;
}

.bg-alert {
  background-color: #FFDEBF;
}

// .job-table-list{
//   tr:last-child {
//     background-color: #dff0d8;
//     .bg-complete-action {
//       background-color: #dff0d8;
//     }
//   }
// }

.none-backgroud-color * {
  background-color: unset !important;
}
  
.MuiBox-root {
  .MuiBox-root-108 {
    background-color: transparent;
  }
  &.MuiBox-root-109 {
    background-color: transparent;
  }
}

.table-last-child {
  .custom-container {
          table {
            thead{
                tr{
                    th:last-child{
                        position: sticky;
                        right:0;
                        text-align: center;
                        background-color: #ababad;
                        z-index: 10;
                    }
                }
            }
            tbody{
              tr.bg-complete {
                td:last-child{
                  background-color: #dff0d8!important;
                }
              }
            }
          }
  }
}

.own-job{
  .custom-container {
          table {
            thead{
                tr{
                    th:first-child{
                        position: sticky;
                        left:0;
                        text-align: center;
                        background-color: #ababad;
                        z-index: 15;
                    }
                }
            }
            tbody{
              tr{
                td:first-child{
                  position: sticky;
                  background-color: #FFFFFF;
                  left: 0;
                  z-index: 15;
                }
              }
              tr.bg-complete {
                td:first-child{
                  background-color: #dff0d8;
                }
                td:last-child{
                  background-color: #dff0d8!important;
                }
              }

            }
          }
  }
}

.job-list-action {
  
  button {
    width : 12em
  }

}
.sms-table {
  width: 100%;
}
.margin_right_10px {
  margin-right: 10px;
}
.margin_left_10px{
  margin-left: 10px;
}
.margin_top_30px{
  margin-top: 30px;
}