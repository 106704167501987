.signin {
    display: flex;
    position: inherit;
    .cover {
        // border-radius: 50%;
        width: 100%;
        min-height: 100vh;
        background: linear-gradient(8deg, rgba(0,124,222,1) 0%, rgba(2,175,149,1) 100%);
        background-size: cover;
        position: relative;
        @include respond-below('lg') {
            display: none;
        }
        &__text {
            position: absolute;
            margin: auto 80px;
            color: white;
            font-size: 40px;
            line-height: 51px;
            letter-spacing: 0;
            font-weight: bold;
            bottom: 100px;
            z-index: 320;
            width: 620px;
        }
        &__footer {
            position: absolute;
            bottom:0;
            right: 0;
        }
    }
    .login {
        position:absolute;
        border-top-left-radius: 50vh !important;
        border-bottom-left-radius: 50vh !important;
        background-color: #ffffff;
        z-index: 300;
        right: 0;
        width: 50%;
        height : 100%;
        min-height: max-content;
        display: flex;
        flex-direction: column;
        @include respond-below('lg') {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            background-color: transparent;
            width: 100%;
            right: auto;
            position:relative;
        }
        .form-title {
            text-align: left;
            font-size: 36px;
            font-weight: bolder;
            color: black;
            margin: 16px 0;
        }
        .form-title-inner {
            text-align: left !important;
            font-size: 16px;
            color: black;
            margin: 16px 0;
            text-align: center;
            padding: 0px;
        }
        .form-title-bg {
            padding: 20px;
            background-color: rgba(201, 201, 201, 0.514);
            border-radius: 10px;
            margin: 16px 0;
            }
        .form-description {
            font-size: 18px;
            text-align: left;
            margin: 16px 0;
            color: black;
        }
        .form-wrapper {
            max-width: 368px;
            margin: 0 auto;
            top: 140px;
            position: absolute;
            left: calc(50% - 184px);
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center; 
        }
    }
    .signup {
        position:absolute;
        border-top-left-radius: 50vh !important;
        border-bottom-left-radius: 50vh !important;
        background-color: #ffffff;
        z-index: 300;
        right: 0;
        width: 50%;
        height : 100%;
        min-height: max-content;
        display: flex;
        flex-direction: column;
        @include respond-below('lg') {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            background-color: transparent;
            width: 100%;
            right: auto;
            position:relative;
        }
        .form-title {
            text-align: left;
            font-size: 30px;
            color: black;
            margin: 16px 0px 0px 0;
        }
        .form-title-inner {
            text-align: left !important;
            font-size: 16px;
            color: black;
            margin: 16px 0;
            text-align: center;
            padding: 0px;
        }
        .form-title-bg {
            padding: 20px;
            background-color: rgba(201, 201, 201, 0.514);
            border-radius: 10px;
            margin: 16px 0;
            }
        .form-description {
            font-size: 14px;
            text-align: left;
            margin: 0px 0px 16px 0;
            color: black;
        }
        .MuiBox-root-7 {
            margin-top: 5px;
        }
        .form-wrapper {
            max-width: 368px;
            margin-left:auto;
            margin-right: 120px;
            // flex: 1;
            display: flex;
            justify-content: end !important;
            align-items: flex-end !important; 
            @include respond-below('lg') {
                margin: 0 auto;
                display: flex;
                justify-content: center !important;
                align-items:center !important; 
            }
        }
    }
    .top-menu {
        display: flex;
        align-items: center;
        justify-content: end;
        @include respond-below('sm') {
            flex-direction: column;
        }
        .logo {
            margin: 32px 56px;
            img {
                width: auto;
            }
        }
        .signup-acc {
            align-items: inherit;
            margin: 0 32px;
            margin-top: 16px;
            div {
                margin-right: 16px;
                font-size: 16px;
                &a {
                    color: #007CDE !important;  
                    cursor: pointer;
                }
            };
            
        }
    }
}

.resetPass {
    font-size: 16px;
    color: #007CDE;
    cursor: pointer;
}

.sign-in-btn {
    background: linear-gradient(25deg, rgba(0,124,222,1) 0%, rgba(2,175,149,1) 100%) !important;
    cursor: pointer;
}