.custom-toast {
    &.Toastify__toast-container {
        width: auto;
        min-width: 200px;
    }

    .Toastify__toast {
        margin: 10px 0;
        border-radius: 8px;
        min-height: 56px;
        align-items: center;
        display: flex;
    }
    .Toastify__toast--default {
        background-color: #E8E8E8;
        
        .Toastify__toast-body {
            color: $color-secondary;

            span {
                color: $color-secondary;
            }
            
            .material-icons {
                color: $color-active;
            }
        }
    }
    .Toastify__toast--dark {
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast--info {
        background-color: $color-info;
        
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast--success {
        background-color: $color-active;
        
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast--warning {
        background-color: $color-warning;
            
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast--error {
        background-color: $color-second;
        
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast-body {
        padding-left: 15px;
        padding-right: 15px;
        margin: 0px;
        font-size: 16px;
        font-family: $font-family-regular;

        > div {
            display: flex;
            align-items: center;
        }
        .material-icons {
            padding-right: 10px;
        }
    }

    .Toastify__close-button {
        // align-self: center;
        // padding-top: 4px;
        border-radius: 50%;
        background-color: #D7D7D7;
        width: 30px;
        height: 30px;
        opacity: 0.7 !important;
        margin: 5px;
        svg {
            color: #E8E8E8 !important;
            height: 26px !important;
            width: 18px !important;
        }
    }
}

.toast-layout {
    background-color: #E8E8E8;
    max-width: 350px;
    min-width: 250px;
    padding:20px 0px;
}

.rotate-icon {
    transform: rotate(180deg);
}

.toast-layout-icon {
    background-color: #D7D7D7;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:15px;
    img {
        width: 30px;
        height:30px;
    }
}

.toast-layout-icon-size {
    width: 30px;
    height:30px;
}
.Toastify__toast {
    box-shadow: none !important;
}

.Toastify__toast-container--top-right {
    top: 4em !important;
}