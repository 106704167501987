.custom-card-component {
    background-color: white;
    border: 1px solid #EAEDF2;
    border-radius: 10px;
    padding: 20px 25px;
    // margin: 20px 25px;

    @include respond-below('lg') {
        margin: 10px 10px;
        padding: 10px 10px;
    }

    &.no-padding {
        padding: 0px !important;
    }

    &.no-margin {
        margin: 0px !important;
    }

    &.no-radius {
        border-radius: 0px !important;
    }

    &.no-border {
        border: 0px !important;
    }
}

.progress-num-color-1 {
    color: #01E8DB ;
}

.progress-num-color-2 {
    color: #0FBEA3 ;
}

.progress-num-color-3 {
    color: #0A927D ;
}

.progress-num-color-4 {
    color: #007CDE ;
}

.progress-style-1 {
    border-radius: 30px;
    div{
    background-color: #01E8DB !important;
    }
}

.progress-style-2 {
    border-radius: 30px;
    div{
    background-color: #0FBEA3 !important;
    }
}

.progress-style-3 {
    border-radius: 30px;
    div{
    background-color: #0A927D !important;
    }
}

.progress-style-4 {
    border-radius: 30px;
    div{
    background-color: #007CDE !important;
    }
}

.progress-style-red {
    border-radius: 30px;
    div{
    background-color: #FF6060 !important;
    }
}

.progress-style-yellow {
    border-radius: 50px;
    div{
    background-color: #FBB214 !important;
    }
}

.progress-style-green {
    border-radius: 50px;
    div{
    background-color: #1BCB80 !important;
    }
}

.progress-style-blue {
    border-radius: 50px;
    div{
    background-color: #2F76E5 !important;
    }
}