﻿.custom-menu-left {
    hr {
        border: 1px solid #969696;
    }

    .MuiDrawer-paper {
        background: #555555 0% 0% no-repeat padding-box;

        nav {
            .custom-logo {
                margin: auto;
                justify-content: center;
                align-self: center;
                align-items: center;
                text-align: center;
            }

            .custom-item {
                padding: 13px 20px;

                a {
                    padding: 5px 15px;
                    border-radius: 7px;
                    display: flex !important;
                    width: 100%;
                    display: contents;
                    text-decoration: none;
                    position: relative;
                    i, svg {
                        padding-right: 10px;
                    }

                    span, i {
                        color: #ffffff !important;
                    }

                    svg {
                        width: 35px;
                        margin-top: 2px;
                        fill: #ffffff !important;;

                        rect, g, path {
                            fill: #ffffff !important;;
                        }
                    }

                    .custom-expand-right {
                        height: 18px;
                        padding-right: 3px;
                        right: 0;
                        margin-top: 0px;
                        padding-top: 5px;
                        position: absolute;
                    }
                    .custom-expand-down {
                        height: 15px;
                        padding-right: 3px;
                        right: 0;
                        margin-top: 0px;
                        padding-top: 7px;
                        position: absolute;
                    }

                    .no-icon {
                        width: 34px;
                    }
                }
                
                &:hover {
                    a {
                        background: #75787B 0% 0% no-repeat padding-box;

                        span, i {
                            color: #FFFFFF;
                        }

                        svg {
                            fill: #FFFFFF !important;
    
                            rect, g, path {
                                fill: #FFFFFF !important;
                            }
                        }
                    }
                }
                &.active {
                    a {
                        background: #75787B 0% 0% no-repeat padding-box;

                        span, i {
                            color: #FFFFFF;
                        }

                        svg {
                            fill: #FFFFFF !important;
    
                            rect, g, path {
                                fill: #FFFFFF !important;
                            }
                        }
                    }
                }
            }

            .sub-nav {
                background: #424242 0% 0% no-repeat padding-box;

                .custom-item {
                    padding: 5px 20px;

                    &:hover {
                        a {
                            background: #424242 0% 0% no-repeat padding-box;
                            word-wrap: break-word !important;
                            span, i {
                                color: #FFFFFF;
                                word-wrap: break-word !important;
                            }

                            svg {
                                fill: #FFFFFF !important;
        
                                rect, g, path {
                                    fill: #FFFFFF !important;
                                }
                            }
                        }
                    }

                    &.active {
                        a {
                            background: #424242 0% 0% no-repeat padding-box;
    
                            span, i {
                                color: #FFFFFF;
                            }

                            svg {
                                fill: #FFFFFF !important;
        
                                rect, g, path {
                                    fill: #FFFFFF !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom-menu-left-customer {
    hr {
        border: 1px solid #969696;
    }

    .MuiDrawer-paper {
        background: #007CDE 0% 0% no-repeat padding-box;
        // overflow-y: hidden !important;
        border-right: 1px solid #FFFFFF;
        &::-webkit-scrollbar {
            display: none;
          }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        nav {
            position: relative;
            .custom-logo {
                margin: auto;
                justify-content: center;
                align-self: center;
                align-items: center;
                text-align: center;
            }
            .custom-item {
                // padding:  8px 0px !important;
                // &:nth-last-child() {
                //     background: #93ff17 0% 0% no-repeat padding-box;
                //     display: flex;
                //     align-items: flex-end;
                //     position: fixed;
                //     bottom: 0
                // }

                a {
                    padding: 14px 5px;
                    // border-radius: 7px;
                    display: flex !important;
                    width: 100%;
                    display: contents;
                    text-decoration: none;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    .icon-style {
                        // height :0.8em;
                        margin-bottom: 0 em;
                        color: #ffffff !important;
                    }
                    .nav-text {
                        font-size: 12px;
                        color: #FFFFFF;
                        white-space: pre-wrap;
                        white-space: -moz-pre-wrap;
                        white-space: -pre-wrap;
                        white-space: -o-pre-wrap;
                        word-wrap: break-word;
                    }
                    .custom-expand-right {
                        height: 18px;
                        padding-right: 3px;
                        right: 0;
                        margin-top: 0px;
                        padding-top: 5px;
                        position: absolute;
                    }
                    .custom-expand-down {
                        height: 15px;
                        padding-right: 3px;
                        right: 0;
                        margin-top: 0px;
                        padding-top: 7px;
                        position: absolute;
                    }

                    .no-icon {
                        width: 34px;
                    }
                }
                
                &:hover {
                    a {
                        background: #75787B 0% 0% no-repeat padding-box;

                        span, i {
                            color: #FFFFFF;
                        }
                        .nav-text {
                            font-size: 12px;
                            color: #FFFFFF;
                        }
                        svg {
                            fill: #FFFFFF !important;
    
                            rect, g, path {
                                fill: #FFFFFF !important;
                            }
                        }
                    }
                }
                &.active {
                    a {
                        background: #F4F6F7 0% 0% no-repeat;
                        span, i {
                            color: #007CDE;
                        }
                        .nav-text {
                            font-size: 12px;
                            color: #007CDE;
                        }
                        svg {
                            fill: #007CDE !important;
    
                            rect, g, path {
                                fill: #007CDE !important;
                            }
                        }
                    }
                }
            }

            .sub-nav {
                background: #424242 0% 0% no-repeat padding-box;
                li {
                    word-wrap: break-all !important;
                    overflow-wrap: break-word !important;
                    white-space:normal !important;
                    text-align: center !important;
                    }
               
                .custom-item {
                    padding: 5px 20px;
                    word-wrap: break-word !important;
                    &:hover {
                        a {
                            background: #424242 0% 0% no-repeat padding-box;
                            word-wrap: break-word !important;
                            span, i {
                                color: #FFFFFF;
                                word-wrap: break-word !important; 
                            }

                            svg {
                                fill: #FFFFFF !important;
        
                                rect, g, path {
                                    fill: #FFFFFF !important;
                                }
                            }
                        }
                    }

                    &.active {
                        a {
                            background: #ffffff 0% 0% no-repeat padding-box;
                            span, i {
                                color: #FFFFFF !important;
                            }

                            svg {
                                fill: #FFFFFF !important;
        
                                rect, g, path {
                                    fill: #FFFFFF !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

img.service-icon {
    display: block;
    width: 36px;
    margin-right: 10px;
    object-fit: contain;
}

.dark-select {
    background-color: #444 !important;
    color: #fff !important;
    font-size: 12px !important;
    transition: all .3s ease-in;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    white-space: initial !important;

    &:hover {
        background-color: darken(#444, 10%) !important;
    }
}

.MuiMenu-list {
    padding: 0 !important;
}

#servicesMenuSelector {
    padding: 0 15px;

    .MuiSelect-icon {
        color: #fff !important;
    }

    label {
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        display: block;
        margin-bottom: 5px;
    }

    .service-icon {
        float: left;
        width: 32px;
        height: 32px;
        border-radius: 7px;
        margin-right: 10px;
        display: block;
    }

    .MuiInputBase-root {
        background-color: #424242;
        border-radius: 7px;
        color: #fff;
        font-size: 12px;
        line-height: 16px;
        width: 100%;
    }

    .MuiInput-underline {
        &:before, &:after {
            border-bottom: none;
        }
    }

    .MuiSelect-selectMenu {
        padding: 15px;
        white-space: normal;
    }
}

.customer-nav-style {
    li {
        padding: 0px !important;
    }
}

.select-dates-step-cus {  
    div{
            border-radius: 4px !important;
            background-color: #007CDE !important;
    }
        
}

.cd-shadow {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.assign-tag {
    background-color: #D9D9D9;
    border-radius: 50px;
    padding: 3px 10px;
    min-width: 30px !important;
    width: 40px !important;
    text-align:center;
    color: #ffffff;
    margin:4px;
}
.progress-tag {
    background-color: #FF8819;
    border-radius: 50px;
    padding: 3px 10px;
    min-width: 30px !important;
    width: 40px !important;
    text-align:center;
    color: #ffffff;
    margin:4px;
}
.cancel-tag {
    background-color: #E7EC00;
    border-radius: 50px;
    padding: 3px 10px;
    min-width: 30px !important;
    width: 40px !important;
    text-align:center;
    color: #ffffff;
    margin:4px;
}

.text-color-cus {
    color: #02AF95;
}

.btn-group {
    .btn-primary {
        background: rgba(217, 217, 217, 0.50);
        color: #6c6c6c ;
    }
}

.active-toggle-btn {
    border-radius: 0px solid #ffffff !important;
    background-color: #ffffff !important;
    color: #000 !important;
    font-weight: 900;
}


.max-height-input {
    max-width: 480px !important;
    width: 380px !important;
    @include respond-below('xs') {
        width: 80% !important;
    }
}

.max-height-btn {
    max-width: 150px !important;
    width: 150px !important;
    @include respond-below('xs') {
        width: 80% !important;
    }
}

.max-height-btn-250 {
    // font-size: 12px;
    max-width: 240px !important;
    width: 240 !important;
    // margin-left: 1em;
    @include respond-below('xs') {
        width: 80% !important;
    }
}

.upload-style-height {
    min-height: 50px;
    height: 50px;
    border-radius: 50px;
    margin-left: 1em;
    background-color: #007CDE !important;
    border: 1px solid #007CDE;
    &:hover {
    background-color: #007edec0 !important;
    border: 1px solid #007edec0;
    }
    svg {
        path {
        fill: #ffffff !important;
        }
    }
    @include respond-below('xs') {
        width: 80% !important;
     }
     @include respond-below('lg') {
        margin-left: 1em !important;
        justify-self: unset !important;
     }
}


.search-input-style {
    border: 1px solid #d3d3d3;
    border-radius: 4px;
}

.max-width-select {
    width: 85px !important;
}
