.custom-checkbox-component {
    position: relative;
    
    &.top-4 {
        top: -4px;
    }

    *:not(i) {
        text-transform: capitalize !important;
    }

    > span {
        padding: 0px;
    }

    .checkbox-label-style {
        position: relative;
        top: 2px;
        // margin-left: 5px;
        cursor: pointer;
        font-size: 14px;

        &.boldCB {
            font-weight: bold;
        }

        .desc-muted {
            font-size: 12px;
            color: rgba($color-secondary, 0.60);
            padding-left: 31px;
            cursor: pointer;
        }
    }

    .MuiCheckbox-colorPrimary {
        svg {
            color: #D5D6D9 !important;
        }
    }

    .MuiCheckbox-colorSecondary {
        svg {
            color: #D5D6D9 !important;
        }
    }

    .Mui-checked  {
        &.MuiCheckbox-colorPrimary {
            svg {
                color: $color-active !important;
            }
        }
    
        &.MuiCheckbox-colorSecondary {
            svg {
                color: $color-second !important;
            }
        }
    }

    .Mui-disabled  {
        &.MuiCheckbox-colorPrimary {
            &.checkbox-disabled-ignore {
                svg {
                    color:  #007CDE !important;
                }
            }
        }
    }
    .Mui-disabled  {
        &.MuiCheckbox-colorPrimary {
            svg {
                color:  #D5D6D9 !important;
            }
        }
    
        &.MuiCheckbox-colorSecondary {
            svg {
                color:  #D5D6D9 !important;
            }
        }
    }
}
.custom-input-parent {
    display: block;
    .custom-input-component input {
        padding-top: 35px;
    }
}
.custom-inline {
    display: inline-block;
}
.custominputgroup {
    border: 1px solid black;
    background-color: #fff;
    position: relative;
    width: 33%;
    
    label {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
      width: 100%;
      display: block;
      color: #3C454C;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;

      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        background-color: #d6d6d6;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }

      &:after {
        display: none;
        width: 32px;
        height: 32px;
        content: '';
        border: 2px solid #D1D7DC;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: 2px 3px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }
    }

    input:checked ~ label {
      color: #fff;

      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }

      &:after {
        background-color: #54E0C7;
        border-color: #54E0C7;
      }
    }

    input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
  }

.check-box-position {
    text-align: start;
}