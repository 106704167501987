.signup {
    display: flex;
    height: 100%;
    .signup-left {
        max-width: 700px;
        width: 100%;
        background-size: cover;
        position: relative;
        @include respond-below('lg') {
            display: none;
        }
        .signup-bg{
            img {
                max-width: 600px;
                width: 100%;
                position: relative;
            }
            
        }
        &__text {
            position: absolute;
            margin: 70px 45px;
            color: white;
            font-size: 40px;
            line-height: 51px;
            letter-spacing: 0;
            font-weight: bold;
            bottom: 0;
        }
        .middle-text {
            .middle-text-title{
                color: black;
                text-align: center;
                font-size: 28px;
                font-weight: bold;
            }
            .middle-text-description{
                color:black;
                text-align: center;
                font-size: 16px;
                font-weight: lighter;
            }
        }
        .footer {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 200px;
        }
    }
    .signup-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        .form-title {
            text-align: left;
            font-size: 36px;
            color: black;
            margin: 16px 0px 0px 0px;
        }
        .form-description {
            font-size: 18px;
            text-align: left;
            margin: 16px 0;
            color: black;
        }
        .form-wrapper {
            max-width: 368px;
            margin: 0 auto;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center; 
        }
    }
    .top-menu {
        display: flex;
        align-items: center;
        justify-content: end;
        @include respond-below('sm') {
            flex-direction: column;
        }
        .logo {
            margin: 32px 56px;
            img {
                width: auto;
            }
        }
        .-acc {
            align-items: inherit;
            margin: 0 32px;
            margin-top: 16px;
            div {
                margin-right: 16px;
                font-size: 16px;
                &a {
                    color: #007CDE !important;  
                    cursor: pointer;
                }
            };
            
        }
    }
    @media (max-width: 420px) {
      .position-absolute {
        top: 4em;
        left: 5%;
      }
    }
    .alert-style {
      top: 4em;
    }
}

.footer-position {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 200px;
}