.rich-text-editor-component {
    .rdw-editor-toolbar {
        border: 1px solid #E0E1E4;
        margin: 0px;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .editor-class {
        min-height: 160px;
        background-color: white;
        border: 1px solid #E0E1E4;
        border-top: 0px;
        padding: 10px;

        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        .public-DraftStyleDefault-block {
            margin: 0px !important;
        }

        .public-DraftEditorPlaceholder-inner {
            color: rgba($color-secondary, 0.60) !important;
        }
    }
}

.rich-sun-editor-component {
    .se-toolbar-sticky {
        position: relative !important;
        top: 0px !important;
    }

    .se-toolbar-sticky-dummy {
        height: 0px !important;
    }
}