.custom-tooltip-component {
    font-size: 25px !important;
    position: absolute;
    top: -1px;
    margin-left: 10px;
    color: rgba($color-secondary, 0.60) !important;
    cursor: help;

    &.txt-dark {
        color: $color-secondary !important;
    }

    &-span {
        font-size: 12px !important;
        color: rgba($color-secondary, 0.60) !important;
    }
}

div {
    &[role="tooltip"] {
        pointer-events: unset !important;
        z-index: 99999;
    }
}

//  ----------------------------------------------------------------
//  Tooltip Style Lib
//  ----------------------------------------------------------------

//Color-palete
$black: #000;
$white: #fff;
// $worknode-theme-color: #64CCC9;
$worknode-theme-color: #007CDE;

//Primary-color
$tooltip-base-text-color: $white;
$tooltip-base-bg-color: $black;
//Types
$tooltip-danger-bg-color: $worknode-theme-color;
//Features

.preview-title {
    margin: 40px 0 20px;
    font-size: 24px;
    font-weight: normal;
  }
  
  .tooltip-content {
    max-width: 960px;
    margin: 0 auto;
    padding: 30px 60px;
    z-index: 110000;
    overflow: visible;
    > span {
      font-size: 24px;
      display: block;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
  
  [data-tooltip] {
    position: relative;
    &:before,
    &:after {
      opacity: 0;
      position: absolute;
      left: 50%;
      backface-visibility: hidden;
      z-index: 110000;
      overflow: visible;
    }
    &:before {
      border-style: solid;
      border-top-width: 4px;
      border-right-width: 4px;
      border-bottom-width: 4px;
      border-left-width: 4px;
      border-bottom-width: 0;
      content: '';
      top: -2px;
      width: 0;
      height: 0;
      transform: translate(-50%, calc(-50% - 6px));
      transition: opacity .1s cubic-bezier(.73, .01, 0, 1) 0s, transform .6s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) 0s;
      z-index: 110000;
      overflow: visible;
    }
    &:after {
      content: attr(data-tooltip);
      text-align: center;
      padding: 10px 10px 9px;
      font-size: 12px;
      border-radius: 8px;
      color: $tooltip-base-text-color;
      transition: opacity .3s cubic-bezier(.73, .01, 0, 1), transform .3s cubic-bezier(.73, .01, 0, 1), -webkit-transform .3s cubic-bezier(.73, .01, 0, 1);
      pointer-events: none;
      z-index: 999;
      white-space: nowrap;
      bottom: 100%;
      transform: translate(-50%, 12px);
      max-width: 320px;
      text-overflow: ellipsis;
      overflow: hidden;
      z-index: 110000;
      overflow: visible;
    }
  }
  
  [data-tooltip]:after {
    background: $tooltip-base-bg-color;
    z-index: 110000;
    overflow: visible;
  }
  
  [data-tooltip]:before {
    border-top-color: $tooltip-base-bg-color;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    z-index: 110000;
    overflow: visible;
  }
  
  [data-tooltip] {
    &:focus,
    &:hover {
      &:before,
      &:after {
        opacity: 1;
        z-index: 110000;
        overflow: visible;
      }
    }
    &:focus,
    &:hover {
      &:before {
        transition: opacity .1s cubic-bezier(.73, .01, 0, 1) .1s, transform .6s cubic-bezier(.73, .01, 0, 1) .1s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .1s;
        transform: translate(-50%, calc(-50% - 2px));
        z-index: 110000;
        overflow: visible;
      }
      &:after {
        transform: translate(-50%, -6px);
        z-index: 110000;
        overflow: visible;
      }
    }
  }
  
  
  [data-tooltip][data-tooltip-conf*=danger]:after {
    background: $tooltip-danger-bg-color;
    z-index: 110000;
    overflow: visible;
  }
  
  [data-tooltip][data-tooltip-conf*=danger]:before {
    border-top-color: $tooltip-danger-bg-color;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    z-index: 110000;
    overflow: visible;
  }
  
  [data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=right]:before {
    border-top-color: transparent;
    border-right-color: $tooltip-danger-bg-color;
    border-bottom-color: transparent;
    border-left-color: transparent;
    z-index: 110000;
    overflow: visible;
  }
  
  [data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=bottom]:before {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: $tooltip-danger-bg-color;
    border-left-color: transparent;
    z-index: 110000;
    overflow: visible;
  }
  
  [data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=left]:before {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: $tooltip-danger-bg-color;
    z-index: 110000;
    overflow: visible;
  }
  
  [data-tooltip][data-tooltip=''] {
    &:after,
    &:before {
      display: none;
            z-index: 110000;
            overflow: visible;
    }
  }
  

  .tooltip {
    overflow: visible;
    z-index: 30000;
  }

  .tooltip--left {
    &:after {
      top: -4px;
      left: 0;
      transform: translateX(-112%) translateY(0);
      height: 42px;
    }
    &:hover {
      &:after {
        transform: translateX(-110%) translateY(0);
      }
    }
  }

  @-webkit-keyframes scale-up-tl {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
  }
  @keyframes scale-up-tl {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
  }

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
  
  .image-preview {
    position: relative !important;
    height: 80px;
    overflow: hidden;
    img {
      object-fit: contain !important;
    }
     span {
      display: none !important;
     }
    &:hover {
      span {
        display: block !important;
        -webkit-animation: scale-up-tl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	      animation: scale-up-tl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        z-index: 300;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        position: absolute !important;
        right: -305px !important;
        top: 0 !important;
        background-color: #fff;
        width: 300px;
        height: auto;
        padding:5px;
        border-radius: 8px;
        .hovered-image {
          width: 100% ;
          border-radius: 8px;
          margin: auto;
          align-self: center !important;
          justify-self: center !important;
        }
       }
    }
  }
  
  .image-preview-detail {
    position: relative !important;
    .image-container {
      display: none !important;
     }
    &:hover {
      .image-container {
        display: block !important;
        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	      animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        z-index: 300;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        position: absolute !important;
        top: -220px !important;
        background-color: #fff;
        width: 350px;
        height: auto;
        padding:5px;
        border-radius: 8px;
        .hovered-image {
          width: 100% ;
          border-radius: 8px;
          margin: auto;
          align-self: center !important;
          justify-self: center !important;
        }
       }
    }
  }