.onboarding-page {
    .custom-container {
        height: 100vh;
        align-items: center;
        display: flex;
    }

    .custom-item {
        padding-bottom: 10px;

        .custom-circle {
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $color-primary;
            color: white;
            text-align: center;
            line-height: 30px;
            font-size: 17px;
        }
    
        .custom-title {
            position: relative;
            padding-left: 40px;
            height: 30px;
            line-height: 30px;
            font-size: 17px;
            font-weight: bold;
            color: rgba(#484848, 0.90) !important;
        }

        .custom-desc {
            position: relative;
            padding-left: 40px;
            color: rgba(#484848, 0.70) !important;
            margin-top: 5px;
        }
    }
}