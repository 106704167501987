.custom-label-component {
    position: relative;
    margin: 1px;

    &.bg {
        background-color: $bg-color;
    }

    label {
        font-size: 14px;
        font-family: $font-family-medium;
        color: $color-secondary;
        margin-bottom: 10px;
        width: auto;
        display: inline-block;

        &.Mui-error {
            border: 0px !important;
        }

        &.MuiInputLabel-shrink {
            transform: unset;
        }

        small {
            font-family: $font-family-regular;

            &.muted {
                color: rgba($color-secondary, 0.60) !important;
            }
        }
    }

    &.text-danger {
        label {
            color: $color-danger;
        }
    }

    &.muted-label {
        label {
            font-weight: bold !important;
            color: rgba($color-secondary, 0.60) !important;
        }
        
        .custom-label {
            label {
                font-weight: bold !important;
                color: rgba($color-secondary, 0.60) !important;
            }
        }
    }

    &.muted {
        label {
            color: rgba($color-secondary, 0.60) !important;
        }
    }
    
    &.no-margin {
        label {
            margin: 0px;
        }
    }
    
    &.word-break {
        label {
            word-break: break-all;
        }
    }
    
    &.xs {
        label {
            font-size: 12px !important;
        }
    }
    &.sm {
        label {
            font-size: 14px !important;
        }
    }
    &.md {
        label {
            font-size: 16px !important;
        }
    }
    &.lg {
        label {
            font-size: 18px !important;
        }
    }
    &.xl {
        label {
            font-size: 20px !important;
        }
    }

    &.lh-xs {
        line-height: 12px !important;
    }
    &.lh-sm {
        line-height: 14px !important;
    }
    &.lh-md {
        line-height: 16px !important;
    }
    &.lh-lg {
        line-height: 18px !important;
    }
    &.lh-xl {
        line-height: 20px !important;
    }

    &.bold {
        label {
            font-family: $font-family-medium;

            small {
                font-family: $font-family-regular;
            }
        }
    }
}

.font-medium {
    h1, h2, h3, h4, h5, h6,
    p, span, label, div,
    input, button, select, textarea, a
    table, th, td {
        font-family: $font-family-medium !important;
    }
    // font-weight: normal !important;
    font-family: $font-family-medium !important;
} 

.font-bold {
    h1, h2, h3, h4, h5, h6,
    p, span, label, div,
    input, button, select, textarea, a
    table, th, td {
        font-family: $font-family-bold !important;
    }
    // font-weight: normal !important;
    font-family: $font-family-bold !important;
} 

hr.hr_style_width {
    border-top: 1px solid rgb(95, 95, 95);
  } 

.save_change_btn > button {
    border-radius: 50px !important;
    background-color: #007CDE !important;
    padding: 2px 10px !important;
    span{
    color: #ffffff !important;
    }
}

.cancel_btn > button {
    border-radius: 50px !important;
    background-color: transparent !important;
    border: 1px solid #007CDE !important;
    padding: 2px 10px !important;
    span{
        color: #007CDE !important;
        }
}