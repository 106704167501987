.custom-swich-component {
    padding: 12px 0px;
    
    .react-switch-bg {
        div {
            &:first-child {
                span {
                    position: absolute;
                    top: 9px;
                    left: 15px;
                    font-weight: bold;
                    color: $white;
                }
            }
    
            &:last-child {
                span {
                    position: absolute;
                    top: 9px;
                    left: 10px;
                    font-weight: bold;
                    color: $color-secondary;
                }
            }
        }
    }
}

.loadingScreen {
    position:fixed;
    width:100%;
    height:100%;
    top: 0;
    left: 0;
    background: #74747474;
    z-index: 600;
}

.circular_progress {
    position:fixed;
    z-index: 650;
    top:50%;
    left:55%;
}

.upload-style-butom {
    label {
        span {
            padding: 9px 25px !important;
            border-radius: 50px !important;
            margin-left: 1em;
            font-size: 1em;
        }
    }
    button {
        span{
            color: #ffffff !important;
            svg {
                path {
                    fill: #ffffff !important;
                }
            }
        }
    }
}