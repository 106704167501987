.custom-circle-icon-component {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: $color-second;

    display: flex;
    align-items: center;
    justify-content: center;

    i {
        font-size: 20px;
        position: relative;
        color: white;
    }

    svg {
        color: white;
        fill: white;

        rect, g, path {
            fill: white;
        }
    }
}