.live-tracking-page {
    
}


.right-drawer {
    > .MuiDrawer-paper {
        overflow: unset !important;

        @include respond-below('md') {
            overflow: auto !important;
        }
    }
}