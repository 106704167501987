@font-face {
    font-family: 'inter-bold';
    src: url('../../fonts/inter/Inter-Bold.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'inter-light';
    src: url('../../fonts/inter/Inter-Light.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'inter-medium';
    src: url('../../fonts/inter/Inter-Medium.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'inter';
    src: url('../../fonts/inter/Inter-Regular.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'inter-semibold';
    src: url('../../fonts/inter/Inter-SemiBold.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;

}
