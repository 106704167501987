@font-face {
    font-family: 'hk_groteskbold';
    src: url('../../fonts/HKGrotesk/HKGrotesk-Bold.eot');
    src: url('../../fonts/HKGrotesk/HKGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HKGrotesk/HKGrotesk-Bold.woff2') format('woff2'),
         url('../../fonts/HKGrotesk/HKGrotesk-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hk_groteskbold_italic';
    src: url('../../fonts/HKGrotesk/hkgrotesk-bolditalic.eot');
    src: url('../../fonts/HKGrotesk/hkgrotesk-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HKGrotesk/hkgrotesk-bolditalic.woff2') format('woff2'),
         url('../../fonts/HKGrotesk/hkgrotesk-bolditalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hk_groteskitalic';
    src: url('../../fonts/HKGrotesk/hkgrotesk-italic.eot');
    src: url('../../fonts/HKGrotesk/hkgrotesk-italic.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HKGrotesk/hkgrotesk-italic.woff2') format('woff2'),
         url('../../fonts/HKGrotesk/hkgrotesk-italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hk_grotesklight';
    src: url('../../fonts/HKGrotesk/HKGrotesk-Light.eot');
    src: url('../../fonts/HKGrotesk/HKGrotesk-Light.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HKGrotesk/HKGrotesk-Light.woff2') format('woff2'),
         url('../../fonts/HKGrotesk/HKGrotesk-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hk_grotesklight_italic';
    src: url('../../fonts/HKGrotesk/hkgrotesk-lightitalic.eot');
    src: url('../../fonts/HKGrotesk/hkgrotesk-lightitalic.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HKGrotesk/hkgrotesk-lightitalic.woff2') format('woff2'),
         url('../../fonts/HKGrotesk/hkgrotesk-lightitalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hk_groteskmedium';
    src: url('../../fonts/HKGrotesk/HKGrotesk-Medium.eot');
    src: url('../../fonts/HKGrotesk/HKGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HKGrotesk/HKGrotesk-Medium.woff2') format('woff2'),
         url('../../fonts/HKGrotesk/HKGrotesk-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hk_groteskmedium_italic';
    src: url('../../fonts/HKGrotesk/hkgrotesk-mediumitalic.eot');
    src: url('../../fonts/HKGrotesk/hkgrotesk-mediumitalic.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HKGrotesk/hkgrotesk-mediumitalic.woff2') format('woff2'),
         url('../../fonts/HKGrotesk/hkgrotesk-mediumitalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hk_groteskregular';
    src: url('../../fonts/HKGrotesk/HKGrotesk-Regular.eot');
    src: url('../../fonts/HKGrotesk/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HKGrotesk/HKGrotesk-Regular.woff2') format('woff2'),
         url('../../fonts/HKGrotesk/HKGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hk_grotesksemibold';
    src: url('../../fonts/HKGrotesk/HKGrotesk-SemiBold.eot');
    src: url('../../fonts/HKGrotesk/HKGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HKGrotesk/HKGrotesk-SemiBold.woff2') format('woff2'),
         url('../../fonts/HKGrotesk/HKGrotesk-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hk_grotesksemibold_italic';
    src: url('../../fonts/HKGrotesk/hkgrotesk-semibolditalic.eot');
    src: url('../../fonts/HKGrotesk/hkgrotesk-semibolditalic.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/HKGrotesk/hkgrotesk-semibolditalic.woff2') format('woff2'),
         url('../../fonts/HKGrotesk/hkgrotesk-semibolditalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}